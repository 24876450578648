export const DeviceMdmStatus = {
  DRAFT: "DRAFT",
  SUBSCRIBED: "SUBSCRIBED",
  DOWNLOADED: "DOWNLOADED",
  IDENTIFIED: "IDENTIFIED",
  ENROLLMENT_IN_PROGRESS: "ENROLLMENT_IN_PROGRESS",
  ENROLLED: "ENROLLED",
  UNENROLLED: "UNENROLLED",
  DEVICE_WIPED_PENDING: "DEVICE_WIPED_PENDING",
};

export const FilterDeviceMdmStatus = {
  SUBSCRIBED: "SUBSCRIBED",
  ENROLLING: "ENROLLING",
  ENROLLED: "ENROLLED",
  UNENROLLED: "UNENROLLED",
  ENROLLMENT_ERROR: "ENROLLMENT_ERROR",
  BLOCKING_PENDING: "BLOCKING_PENDING",
  BLOCKED: "BLOCKED",
};
