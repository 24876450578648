import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  faChevronDown,
  faChevronUp,
  faExclamationCircle,
  faShieldCheck,
  faShieldXmark,
  faTruck,
} from "@fortawesome/pro-regular-svg-icons";

import { useUserContext } from "src/contexts/UserContext";
import useDeviceType from "src/hooks/useDeviceType";
import useDataLayer from "src/hooks/useDataLayer";

import { hasExtendedDeliveryDelay } from "src/tools/DeviceHelpers";
import { getSpecificationValueLabel, productSpecs } from "src/tools/ProductHelpers";

import MoneyFormat from "src/tools/MoneyFormat";
import { Alert, Avatar, Button, Card, Layout, Link, Text, Tooltip, theme } from "tarmac-v3";
import AddAndRemoveItemButton from "src/components/common/AddAndRemoveItemButton";

const typologyTitles = {
  new: "Neuf",
  asnew: "Certifié Apple",
  refurb: "Reconditionné +",
};

const ShoppingCartModalItem = (props) => {
  const { device, deviceNumber, productId, keyboard, orderInfo, reloadOrderInfo, reloadOrder, setSecureMdmCartDialog } =
    props;
  const { t } = useTranslation();
  const { user } = useUserContext();
  const defaultAddress = user.company.addresses.find((a) => a.is_default);
  const deviceType = useDeviceType();
  const { trackAddToCart, trackRemoveFromCart } = useDataLayer();

  const [isExpanded, setIsExpanded] = useState(false);

  const addToShoppingCart = useCallback(async () => {
    const newDevice = {
      product_id: productId,
      shipping_address_id: defaultAddress?.id,
      ...(device.order_mdm_activation_consent && { order_mdm_activation_consent: true }),
      ...(keyboard && keyboard !== "null" && { keyboard_layout: keyboard }),
    };

    try {
      await user.api.addDeviceToOrder(orderInfo.id, newDevice);
      user.api.trackEvent("ADD_TO_CART");
      // Add same product
      trackAddToCart(device.product, orderInfo);
      reloadOrderInfo();
      reloadOrder();
    } catch (e) {
      console.error(e);
    }
  }, [reloadOrderInfo, reloadOrder, orderInfo, user.api, productId, keyboard, defaultAddress, trackAddToCart, device]);

  const removeFromShoppingCart = useCallback(async () => {
    try {
      await user.api.removeDeviceFromOrder(orderInfo.id, { device });
      trackRemoveFromCart(device.product, orderInfo);
      reloadOrderInfo();
      reloadOrder();
    } catch (e) {
      console.error(e);
    }
  }, [reloadOrderInfo, reloadOrder, orderInfo.id, user.api, device]);

  const getToastContent = useCallback(() => {
    if (!hasExtendedDeliveryDelay(user.company.country, keyboard)) {
      return {
        variant: "action",
        icon: faTruck,
        label: t("Délais de livraison : {{shippingMin}} à {{shippingMax}} jours ouvrés", {
          shippingMin: device.product.min_shipping,
          shippingMax: device.product.max_shipping,
        }),
      };
    }

    return {
      variant: "warning",
      icon: faExclamationCircle,
      label: t("Les claviers étrangers peuvent impacter les délais de livraison"),
    };
  }, [device.product.max_shipping, device.product.min_shipping, keyboard, t]);

  const toastContent = getToastContent();

  const companyDiscount = user.getCompanyDiscount(device.base_price);

  const finalPrice =
    Number(device.rent) + (companyDiscount?.offer_type === "MONTH_OFFERED" ? Number(device.base_price) / 36 : 0);
  const basePrice =
    finalPrice + (companyDiscount?.offer_type === "CURRENCY_DISCOUNT" ? Number(companyDiscount?.offer_value) : 0);

  const hasActiveSubscriptionMdm = user.company.company_mdm && user.company.company_mdm.auto_subscription_enabled;

  return (
    <Layout direction="column" spacing={2}>
      <Layout direction="row" justifyContent="space-between" mobileDirection="column" spacing={2}>
        <Layout direction="row" spacing={2}>
          <Avatar
            variant="circular"
            border
            src={device?.product?.img_url}
            alt={device?.name}
            size={56}
            imgStyles={{ height: "28px" }}
          />

          <Layout direction="column" spacing={0.5}>
            <Text variant="body1" bold>
              {t(device?.name)}
            </Text>
            <Layout direction="row" spacing={1} alignItems="baseline">
              <Text variant="body1" bold>
                <MoneyFormat value={finalPrice.toFixed(2)} />
              </Text>
              <Layout direction="row" spacing={0.5}>
                {companyDiscount?.offer_type === "CURRENCY_DISCOUNT" && (
                  <Text variant="body2" color="secondary" sx={{ textDecorationLine: "line-through" }}>
                    <MoneyFormat value={basePrice} />
                  </Text>
                )}
                <Text variant="body2">{t("/mois")}</Text>
              </Layout>
            </Layout>
            {companyDiscount?.offer_type === "MONTH_OFFERED" && (
              <Layout direction="row" spacing={1}>
                <Text variant="body2" color="success" bold>
                  {t("Offre active : 1 mois offert")}
                </Text>
              </Layout>
            )}
          </Layout>
        </Layout>

        <Layout
          direction="column"
          alignSelf="flex-end"
          alignItems="end"
          fullWidth={deviceType !== "desktop"}
          sx={{ height: "fit-content" }}
          spacing={1}
        >
          {device.category === "COMPUTER" && !hasActiveSubscriptionMdm && (
            <Tooltip
              title={device.order_mdm_activation_consent ? t("Sécurisé par le MDM") : t("Non sécurisé par le MDM")}
              placement="top"
            >
              <Layout>
                <Button
                  startIcon={device.order_mdm_activation_consent ? faShieldCheck : faShieldXmark}
                  size="medium"
                  onClick={() => setSecureMdmCartDialog(!device.order_mdm_activation_consent ? "ENROLL" : "UNENROLL")}
                  color={device.order_mdm_activation_consent ? "secondary" : "warning"}
                  sx={{
                    paddingRight: "4px",
                    border: `1px solid ${theme.palette.grey[300]}`,
                    "&.Mui-disabled .MuiButton-startIcon": {
                      color: device.order_mdm_activation_consent
                        ? theme.palette.secondary.main
                        : theme.palette.warning.main,
                    },
                  }}
                  disabled={device.order_mdm_activation_consent}
                />
              </Layout>
            </Tooltip>
          )}

          <AddAndRemoveItemButton
            clickMinus={removeFromShoppingCart}
            clickPlus={addToShoppingCart}
            listLength={deviceNumber}
          />
        </Layout>
      </Layout>
      <Layout
        direction="row"
        fullWidth
        spacing={2}
        flexWrap={deviceType !== "desktop" ? "wrap" : "nowrap"}
        justifyContent={!isExpanded && "flex-end"}
      >
        <Layout direction="column" spacing={0.5} fullWidth>
          <Layout direction="column" spacing={1} fullWidth>
            <Layout direction="row" justifyContent="flex-start">
              <Link
                label={
                  <Text variant="body2" bold>
                    {isExpanded ? t("Masquer les détails") : t("Afficher les détails")}
                  </Text>
                }
                color="secondary"
                iconRight
                icon={isExpanded ? faChevronUp : faChevronDown}
                onClick={() => setIsExpanded(!isExpanded)}
              />
            </Layout>

            {isExpanded && (
              <Card backgroundColor="secondary" spacing={2}>
                <Layout fullWidth direction="row" rowGap={2} columnGap={5} flexWrap="wrap">
                  <Layout direction="column" spacing={0.5}>
                    <Text variant="body2">{t("État")}</Text>
                    <Text variant="body2" bold>
                      {t(typologyTitles[device.quality])}
                    </Text>
                  </Layout>
                  {productSpecs[device.category].map(
                    (spec) =>
                      device[spec.key] && (
                        <Layout direction="column" spacing={0.5} flexWrap="wrap">
                          <Text variant="body2">{t(spec.label)}</Text>
                          <Text variant="body1" bold>
                            {t(getSpecificationValueLabel(spec.key, device[spec.key]))}
                          </Text>
                        </Layout>
                      ),
                  )}
                </Layout>
              </Card>
            )}
          </Layout>
        </Layout>
      </Layout>
      {device.product.has_keyboard ? (
        <Alert severity={toastContent.variant === "warning" ? "warning" : "info"}>{t(toastContent.label)}</Alert>
      ) : (
        <Alert severity="info">
          {t("Délais de livraison : {{ shippingMin }} à {{ shippingMax }} jours ouvrés", {
            shippingMin: device.product.min_shipping,
            shippingMax: device.product.max_shipping,
          })}
        </Alert>
      )}
    </Layout>
  );
};

export default ShoppingCartModalItem;
