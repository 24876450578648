// User
import ApiService from "src/tools/ApiService";
import GlobalApiService from "src/tools/GlobalApiService";
import { deviceSortFn } from "src/tools/DeviceGrouping";
import { COUNTRIES, CURRENCIES } from "src/common/i18n-consts";

class User {
  visibleOrderStatus = [
    "LEAD_AGREEMENT",
    "LEASER_AGREEMENT",
    "LEASER_REQUESTED",
    "LEASER_MORE_DOCUMENTS",
    "LEASER_DISAGREEMENT",
    "LEAD_SIGNING",
    "READY_FOR_PURCHASE",
    "PURCHASED",
    "SHIPPED",
    "DELIVERED",
    "BDL_SENT",
    "RECEIVED",
    "FINALIZED",
    "CLOSED",
    "INACTIVE",
  ];

  validatedOrderStatus = [
    "READY_FOR_PURCHASE",
    "PURCHASED",
    "SHIPPED",
    "DELIVERED",
    "BDL_SENT",
    "RECEIVED",
    "FINALIZED",
    "CLOSED",
    "INACTIVE",
  ];

  load = () => {
    this.api = new ApiService(this.company_id);
    this.globalApi = new GlobalApiService();
    this.company.devices = this.sortDevices();

    return this;
  };

  isCompanyIsSyncWithHrms = () =>
    this.company.integrations?.some((itg) => itg.status === "ACTIVE" && itg.type === "HRMS");

  isLoaded = (onboarding = false) => {
    if (typeof this.api === "undefined") {
      return false;
    }

    if (typeof this.company === "undefined") {
      return false;
    }

    if (typeof this.company.orders === "undefined") {
      return false;
    }

    if (typeof this.company.devices === "undefined" && !onboarding) {
      return false;
    }

    if (typeof this.company.tickets === "undefined" && !onboarding) {
      return false;
    }

    if (this.deleted) {
      return false;
    }

    return true;
  };

  isAnonymous = () => !this.email;

  getOnboardingStatus = () => {
    switch (this.info_level) {
      case 0:
        return "NOT_STARTED";
      case 1:
        return "STARTED";
      case 2:
        return "IN_PROGRESS";
      case 3:
        return "COMPLETE";
      default:
        return "";
    }
  };

  // Add helpers here
  hasRole = (role) => this.roles && this.roles.filter((r) => r.role === role).length;

  isAdmin = () => this.hasRole("ADMIN") > 0;

  checkIfCompanyIntegrationisActive = (integrationType) =>
    this.company.integrations?.some((itg) => itg.type === integrationType && itg.status === "ACTIVE");

  isSyncedWith = (integration) =>
    this.company.integrations?.some(
      (itg) => itg.provider === integration && itg.status === "ACTIVE" && this.sirh_sync_activated,
    );

  isCompanyOnboarded = () => {
    const members = this.company.members || this.company.employees || [];

    // At least one user has finished onboarding in the company
    const onboardedUser = members.find((m) => m.roles.find((r) => r.role === "ADMIN") && m.info_level === 3);
    const hasEmployeeAccess = this.hasRole("EMPLOYEE") > 0 && this.checkIfCompanyIntegrationisActive("EMPLOYEE_ACCESS");

    return onboardedUser !== undefined || hasEmployeeAccess;
  };

  companyHasMdm = () => {
    if (this.company.company_mdm === null) return false;

    return true;
  };

  canAccessCockpit = () => {
    const isOnboardedAdmin = this.isAdmin();
    const hasEmployeeAccess = this.hasRole("EMPLOYEE") > 0 && this.checkIfCompanyIntegrationisActive("EMPLOYEE_ACCESS");

    return isOnboardedAdmin || hasEmployeeAccess || Boolean(this.email);
  };

  companyInfoCompleted = () => {
    if (
      (this.company?.country === "France" && !this.company?.siren) ||
      (this.company?.country === "Germany" && !this.company?.hr) ||
      (this.company?.country === "Spain" && !this.company?.nif)
    ) {
      return false;
    }

    if (!this.company?.legal_name) {
      return false;
    }

    return true;
  };

  isFirstOrder = () => {
    if (this.company.orders && this.company.orders.length > 0) {
      return !this.company.orders.some((order) => this.visibleOrderStatus.includes(order.status));
    }

    return true;
  };

  sortDevices = () => this.company.devices.sort(deviceSortFn);

  // Return ALL devices, including returned devices
  getVisibleDevices = () => {
    if (!this.company.devices || this.company.devices.length === 0) {
      return [];
    }

    return this.company.devices
      .filter((d) => {
        if (d.source === "EXTERNAL") return true;

        const deviceOrder = this.company.orders.find((o) => o.id === d.order_id);
        const orderIsVisible = deviceOrder && this.visibleOrderStatus.includes(deviceOrder.status);
        const deviceStillInCompany = d.source !== "EX-FLEET";

        return orderIsVisible && deviceStillInCompany;
      })
      .sort(deviceSortFn);
  };

  // Return all devices currently owned/leased by company
  getCurrentDevices = () => {
    if (!this.company.devices || this.company.devices.length === 0) {
      return [];
    }

    return this.company.devices
      .filter((d) => {
        if (d.source === "EXTERNAL") return true;

        // Exclude UPGRADEs
        if (d.renewal_type === "UPGRADE") return false;

        if (d.status === "WAITING") return false;

        const deviceOrder = this.company.orders.find((o) => o.id === d.order_id);
        const orderIsVisible = deviceOrder && this.visibleOrderStatus.includes(deviceOrder.status);

        const deviceStillInCompany = d.source !== "EX-FLEET";

        return orderIsVisible && deviceStillInCompany;
      })
      .sort(deviceSortFn);
  };

  getDevicesForOrder = (orderId) => this.company.devices.filter((d) => d.order_id === orderId);

  getVisibleOrders = () => {
    if (!this.company.orders || this.company.orders.length === 0) {
      return [];
    }

    return this.company.orders.filter((o) => this.visibleOrderStatus.includes(o.status));
  };

  getValidatedOrders = () => {
    if (!this.company.orders || this.company.orders.length === 0) {
      return [];
    }

    return this.company.orders.filter((o) => this.validatedOrderStatus.includes(o.status));
  };

  getLastFinishedOrder = async () => {
    if (!this.company.orders || this.company.orders.length === 0) {
      return null;
    }
    // get the last order in the "LEAD_AGREEMENT" status
    const leadAgreements = this.company.orders.filter((o) => o.status === "LEAD_AGREEMENT");

    if (!leadAgreements.length) return null;

    const lastOrder = leadAgreements.sort((a, b) => new Date(b.order_date) - new Date(a.order_date))[0];

    const fetchedOrder = await this.api.getOrder(lastOrder.id);

    return fetchedOrder.data;
  };

  reloadEmployees = async () => {
    const fetchedList = await this.api.getUsers();

    this.company.employees = fetchedList.data;

    return this;
  };

  getYousignSignatureLink = async (o) => {
    let order = o;

    if (!order.yousign_signature_requests || !order.yousign_procedures) {
      // This endpoint adds missing joins to order object
      // Including order.yousign_signature_requests
      const orderFetched = await this.api.getOrder(o.id);

      order = orderFetched.data;
    }

    const yousignSignatureRequests = order.yousign_signature_requests;

    if (yousignSignatureRequests && yousignSignatureRequests.length > 0) {
      const lastValidSignatureRequest = yousignSignatureRequests
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .find((signatureRequest) => signatureRequest.status === "ONGOING");

      if (
        !lastValidSignatureRequest ||
        order.status !== "LEAD_SIGNING" ||
        !lastValidSignatureRequest.decrypted_client_signature_link
      ) {
        return null;
      }

      return lastValidSignatureRequest.decrypted_client_signature_link;
    }

    const yousignProcedures = order.yousign_procedures;

    if (yousignProcedures && yousignProcedures.length > 0) {
      const yousignBaseUrl = import.meta.env.REACT_APP_YOUSIGN_URL;
      let yousignClientId = null;

      const lastValidProcedure = yousignProcedures
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .find((p) => p.status === "ACTIVE");

      if (!lastValidProcedure) {
        return "";
      }

      if (order.status === "LEAD_SIGNING") {
        yousignClientId = lastValidProcedure.client_first_id;
      }

      if (!yousignClientId) {
        return "";
      }

      return `${yousignBaseUrl}/procedure/sign?members=${yousignClientId}&signatureUi=/${import.meta.env.REACT_APP_YOUSIGN_SIGNATURE_UI}`;
    }

    return "";
  };

  hasFinishedDevicesSetup = () => this.getVisibleDevices().length > 1;

  getMdmAdmin = () => this.company?.company_mdm?.activator;

  isMdmAdmin = () => this.getMdmAdmin()?.id === this.id;

  getMdmGroupId = () => this.company.company_mdm.vmware_group_name.toUpperCase();

  hasFinishedEmployeesSetup = () => this.company.employees.length > 2;

  hasFinishedAdminSetup = () =>
    this.company.employees.filter((u) => u.roles.find((r) => r.role === "ADMIN") && u.id !== this.id).length > 0;

  hasFinishedSetup = () => {
    const setupFinished =
      this.hasFinishedAdminSetup() && this.hasFinishedDevicesSetup() && this.hasFinishedEmployeesSetup();

    return setupFinished || this.company.skip_setup || this.company.setup_done;
  };

  getCompanyDiscount = (price) => {
    const productCompanyDiscount = this.company.product_discounts.find((discount) => {
      const isAboveMinPrice = Number(discount.min_price) <= price;
      const isBelowMaxPrice = !discount.max_price || Number(discount.max_price) >= price;

      return isAboveMinPrice && isBelowMaxPrice;
    });

    return productCompanyDiscount;
  };

  getCompanyActiveOffer = () =>
    this.company?.offers.find((offer) => offer.status === "ACTIVE" && offer.type !== "NO_DISCOUNT");

  getCompanyUsableReferralCredit = () => this.company?.company_credits.find((credit) => credit.used === false);

  getCompanyCurrency = () => {
    switch (this.company?.country) {
      case COUNTRIES.UNITED_KINGDOM:
        return CURRENCIES.GBP;
      case COUNTRIES.UNITED_STATES:
        return CURRENCIES.USD;
      default:
        return CURRENCIES.EUR;
    }
  };

  getProductPrices = (productGroup) => {
    let minBasePrice = null;
    let cheapestProduct = null;
    let minFinalPrice = null;

    for (const product of productGroup.products) {
      const productBasePrice = product.prices.find((p) => p.currency === this.getCompanyCurrency());
      const productBasePriceAmount = productBasePrice?.amount;

      const productDiscountedPriceAmount = this.getDiscountedProductPrice(product);

      if (minBasePrice === null || Number(productBasePriceAmount) < Number(minBasePrice)) {
        minBasePrice = productBasePriceAmount;
        minFinalPrice = productDiscountedPriceAmount;
        cheapestProduct = product;
      }
    }

    return {
      cheapestProduct,
      minBasePrice,
      minFinalPrice,
    };
  };

  getCompanyDefaultAddress = () => {
    const defaultAddress = this.company.addresses.find((a) => a.is_default);
    const hqAddress = this.company.addresses.find((a) => a.is_hq);

    const haveAddress = defaultAddress || hqAddress;

    return (
      haveAddress || {
        address1: "",
        address2: "",
        city: "",
        zip: "",
        country: "",
        label: "",
        firstName: "",
        lastName: "",
        phone: "",
      }
    );
  };

  getDiscountedProductPrice = (product) => {
    const productPrice = product.prices?.find((p) => p.currency === this.getCompanyCurrency())?.amount;

    const discount = this.getCompanyDiscount(productPrice);
    const discountValue = discount?.offer_type === "MONTH_OFFERED" ? 0 : Number(discount?.offer_value) || 0;

    return productPrice - discountValue;
  };

  getMdmElligibleDevices = () => this.getCurrentDevices().filter((d) => d.category === "COMPUTER");

  getMainOrOtherCountry = () => {
    if (!Object.values(COUNTRIES).includes(this.company.country)) {
      return "Other";
    }

    return this.company.country;
  };
}

export default User;
